import ApiService from "@/core/services/api.service";
import router from '@/router';
import querystring from 'querystring';
import _ from 'lodash';

const BASE = 'PANEL-MANAGEMENT/CRUD';

export const LOADING = `${BASE}/LOADING`;
export const ERROR = `${BASE}/ERROR`;
export const SUCCESS = `${BASE}/SUCCESS`;
export const ITEMS = `${BASE}/ITEM`;
export const CURRENT_ITEM = `${BASE}/CURRENT_ITEM`;

export const SET_LOADING = `${BASE}/M/LOADING`;
export const SET_ERROR = `${BASE}/M/ERROR`;
export const SET_SUCCESS = `${BASE}/M/SUCCESS`;
export const SET_ITEMS = `${BASE}/M/ITEMS`;
export const SET_CURRENT_ITEM = `${BASE}/M/CURRENT_ITEM`;
export const RESET_VALUES = `${BASE}/M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `${BASE}/GET_ITEMS`;
export const GET_ITEM_DETAIL_BY_ID = `${BASE}/GET_ITEM_DETAIL_BY_ID`;
export const CREATE_ITEM = `${BASE}/CREATE_ITEM`;
export const UPDATE_ITEM_BY_ID = `${BASE}/UPDATE_ITEM_BY_ID`;
export const DELETE_ITEM_BY_ID = `${BASE}/DELETE_ITEM_BY_ID`;
export const CHANGE_STATUS_BY_ID = `${BASE}/CHANGE_STATUS_BY_ID`;
export const GET_SYNC = `${BASE}/GET_SYNC`;
export const HANDLE_INFINITE_SCROLL = `${BASE}/HANDLE/INFINITE_SCROLL`;


const state = {
    loading: false,
    error: null,
    success: null,
    items: [],
    currentItem: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [ITEMS]: (state) => {
        return state.items;
    },
    [CURRENT_ITEM]: (state) => {
        return state.currentItem;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [SET_ITEMS]: (state, payload) => {
        state.items = payload;
    },
    [SET_CURRENT_ITEM]: (state, payload) => {
        state.currentItem = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
        state.items = {};
        state.currentItem = null;
    },
};

const actions = {
    /**
     * This function will allow to do behind the process of infinite scroll
     *
     *
     * Note: To use this method payload must include filters as filters.
     * Inside the filters, it must have page property to indicate current page
     * $state for infinite scroll (if $state wasn't passed or null it will reset the state)
     *
     * @param context
     * @param payload
     */
    [HANDLE_INFINITE_SCROLL]: (context, payload) => {
        if (!('filters' in payload) || !('page' in payload.filters)) {
            throw "To use the infinite feature, please pass filters with page in payload";
        }
        if (context.state.loading) {
            return new Promise(function (resolve) {
                resolve({
                    status: true,
                    data: payload
                });
            });
        }
        if (document.activeElement !== document.body) document.activeElement.blur();
        context.commit(SET_LOADING, true);

        if (payload.$state == null) {
            context.commit(SET_ITEMS, {});
            payload.filters.page = 1;
        }

        let items = { ...context.state.items };

        if (Number(items.last_page ? items.last_page : Infinity) < payload.filters.page) {
            context.commit(SET_LOADING, false);
            return new Promise(function (resolve) {
                resolve({
                    status: false,
                    end: true,
                    error: "End of the scroll"
                });
            });
        }
        return ApiService.get(`${payload.url}? + ${querystring.stringify(payload.filters)}`).then(response => {
            if (!Object.keys(items).length) {
                items = { ...response.data };
            } else {
                items.data = [...items.data, ...response.data.data];
            }
            delete response.data.data;
            items = { ...items, ...response.data };
            if (Number(items.last_page) >= payload.filters.page) {
                payload.filters.page += 1;
            }
            context.commit(SET_ITEMS, items);
            context.commit(SET_LOADING, false);
            return { status: true, data: payload };
        }).catch(error => {
            context.commit(SET_LOADING, false);
            return { status: false, error };
        });
    },
    [GET_ITEMS]: (context, payload) => {
        let url = payload.url;
        let filters = payload.filters;
        let returnType = payload.returnType;
        context.commit(SET_LOADING, true);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                if (!returnType) {
                    context.commit(SET_ITEMS, response.data);
                }
                context.commit(SET_LOADING, false);

                return { status: true, data: response.data };
            })
            .catch((error) => {
                context.commit(SET_ERROR, error);

            });
    },
    [GET_ITEM_DETAIL_BY_ID]: (context, payload) => {
        let url = payload.url;
        context.commit(SET_LOADING, true);
        return ApiService.get(url)
            .then((response) => {
                context.commit(SET_CURRENT_ITEM, response.data);
                context.commit(SET_LOADING, false);
                return { status: true, data: response.data };
            }).catch((error) => {
                context.commit(SET_ERROR, error);
                router.push({
                    name: payload.redirectRouteOnNotFound,
                    params: {
                        error: 'general.couldnt_found_item_with_given_id'
                    }
                });
            });
    },
    [CREATE_ITEM]: (context, payload) => {
        let url = payload.url;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        return ApiService.post(url, contents).then((_) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_CURRENT_ITEM, null);
            context.commit(SET_ITEMS, []);
            if (payload.redirectRouteName != null) {
                router.push({ name: payload.redirectRouteName, params: { success: payload.successMessage } });
            }
            return { status: true, data: _ };
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [UPDATE_ITEM_BY_ID]: (context, payload) => {
        let url = payload.url;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        return ApiService.put(url, contents)
            .then((_) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_CURRENT_ITEM, null);
                context.commit(SET_ITEMS, []);
                router.push({ name: payload.redirectRouteName, params: { success: payload.successMessage } });

                return { status: true, data: _ };
            }).catch((error) => {
                context.commit(SET_ERROR, error);
            });
    },
    [DELETE_ITEM_BY_ID]: (context, payload) => {
        let url = payload.url;
        let id = payload.id;
        context.commit(SET_LOADING, true);
        ApiService.delete(url).then((_) => {
            if (context.state.items.data !== undefined) {
                for (let i = 0; i < context.state.items.data.length; i++) {
                    let currentItem = context.state.items.data[i];
                    if (Number(id) === Number(currentItem.id)) {
                        context.state.items.data.splice(i, 1);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < context.state.items.length; i++) {
                    if (Number(id) === Number(context.state.items[i].id)) {
                        context.state.items.splice(i, 1);
                        break;
                    }
                }
            }
            context.commit(SET_LOADING, false);

        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [CHANGE_STATUS_BY_ID]: (context, payload) => {
        let url = payload.url;
        let id = payload.id;
        context.commit(SET_LOADING, true);
        ApiService.put(url).then((res) => {
            let dataIndex = _.findIndex(context.state.items.data, row => row.id === id);

            if (dataIndex !== -1)
                context.state.items.data[dataIndex].status = !context.state.items.data[dataIndex].status;

            context.commit(SET_LOADING, false);
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [GET_SYNC]: (context, payload) => {
        let url = payload.url;
        context.commit(SET_LOADING, true);
        context.commit(SET_SUCCESS);

        ApiService.get(url).then((res) => {
            context.commit(SET_LOADING, false);
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    }

};

export default {
    state,
    getters,
    mutations,
    actions
};
