import $ from 'jquery';

export default {
    data: function data() {
        return {
            mediaBase: localStorage.getItem('VUE_APP_MEDIA_URL'),
            baseUrl: localStorage.getItem('VUE_APP_APP_URL'),
        };
    },
    mounted(){
        let bgImage = this.mediaBase + '/bg/ARKAPLAN.png';
        let ktContent = $('#kt_content');

        ktContent.css({
            'backgroundImage': "url('" + bgImage +"')"
        });
    },
};